@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
.footer{
    padding: 100px 0px;
    background-color: #F0F0F0;
}
.footer-logo{
    padding-bottom: 30px;
}
.footer-widget{
    padding: 10px 0px;
}
.footer-widget h4{
    font-size: 18px;
    font-weight: 400;
    letter-spacing: .4px;
    color: #212124;
    padding-bottom: 15px;
}
.footer-widget ul{
    display: block;
    margin: 0;
    padding: 0;
}
.footer-widget ul li{
    display: block;
    padding: 15px 0px;
    font-size: 12px;
}
.footer-widget ul li a{
    font-style: normal;
    color: #212124;
}
.footer-widget ul li a:hover{
    text-decoration: none;
    font-weight: 600;
    color: #212124;
}
.footer-widget p, .footer-widget a{
    font-style: normal;
    color: #212124;
    font-size: 12px;
    margin: 0;
    padding: 0;
}
.footer-widget a:hover{
    text-decoration: none;
    font-weight: 600;
    color: #212124;
}

.footer-social{
    display: block;
    margin: 0;
    padding: 0;
    margin-bottom:30px;
}

.footer-social li{
    display: inline-block;
    padding: 0px 5px;
}
.footer-social li:first-child{
    padding-left: 0;
}
.footer-social li a{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #212124;
    color: #fff;
    display: block;
    text-align: center;
    line-height:40px;
    font-size: 20px;
}
.footer-social li a:hover{
    background-color: #000;
    color: #fff;
    text-decoration: none;
    font-weight: normal;
}