@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
.App {
  text-align: center;
  font-family: 'Poppins', sans-serif;
}
body{
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}
img{
  max-width: 100%;
}
.green-button{
    padding: 15px 60px;
    background-color: #00d28f;
    font-size: 13px;
    font-weight: 600;
    width: 315px;
    border-radius: 8px;
    box-shadow: 0 5px 10px 0 rgb(33 33 36 / 15%);
    -webkit-transition: box-shadow .3s ease;
    transition: box-shadow .3s ease;
    font-family: 'Poppins', sans-serif;
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    text-align: center;
    letter-spacing: .8px;
    text-decoration: none;
    display: inline-block;
    text-transform: uppercase;
}
.green-button:hover{
  box-shadow: 0 5px 10px 0 rgb(33 33 36 / 30%);
  color: #fff;
  text-decoration: none;
}

.purple-button{
  padding: 15px 60px;
  background-color: #7849FF;
  font-size: 13px;
  font-weight: 600;
  width: 315px;
  border-radius: 8px;
  box-shadow: 0 5px 10px 0 rgb(33 33 36 / 15%);
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  font-family: 'Poppins', sans-serif;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  letter-spacing: .8px;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
}
.purple-button:hover{
box-shadow: 0 5px 10px 0 rgb(33 33 36 / 30%);
color: #fff;
text-decoration: none;
}

.purple-button-cta {
  display: block;
  width: 100%;
  margin-top: 0;
  padding-top: 11px;
  padding-bottom: 11px;
  border-radius: 8px;
  background-color: #7849ff;
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  text-align: center;
  letter-spacing: .4px;
}
.purple-button-cta:hover {
  background-color: #663cdf;
  color: #fff;
 text-decoration: none;
}
@media(max-width:980px){
  .purple-button-cta{
    font-size: 10px;
    line-height: 14px;
  }
}