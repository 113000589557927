@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap);
pre {
  border-radius: 4px;
  background-color: #191c27;
  padding: 16px;
  font-size: 14px;
  margin-bottom: 24px
}

code[class*="language-"],
pre[class*="language-"] {
  color: rgb(191, 199, 213);
  font-family: Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace;
  direction: ltr;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  line-height: 1.5;
  tab-size: 4;
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
}

/* Code blocks */
pre[class*="language-"] {
  padding: 1em;
  margin: .5em 0;
  overflow: auto;
  border-radius: 0.3em;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background: #1d1f21;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
  padding: .1em;
  border-radius: .3em;
}

.token.prolog {
  color: rgb(0, 0, 128);
}

.token.parameter {
  color: rgb(255, 255, 255);
}

.token.comment {
  color: rgb(106, 153, 85);
}

.token.doctype {
  color: rgb(191, 199, 213);
}

.token.cdata {
  color: rgb(191, 199, 213);
}

.token.punctuation {
  color: rgb(136, 198, 190);
}

.token.property {
  color: rgb(252, 146, 158);
}

.token.tag {
  color: rgb(252, 146, 158);
}

.token.class-name {
  color: rgb(250, 200, 99);
}

.token.boolean {}

.token.constant {
  color: rgb(100, 102, 149);
}

.token.symbol {
  color: rgb(141, 200, 145);
}

.token.deleted {
  color: rgb(141, 200, 145);
}

.token.number {
  color: rgb(181, 206, 168);
}

.token.inserted {
  color: rgb(181, 206, 168);
}

.token.selector {
  color: rgb(215, 186, 125);
}

.token.char {
  color: rgb(209, 105, 105);
}

.token.builtin {
  color: rgb(197, 165, 197);
}

.token.changed {
  color: rgb(197, 165, 197);
}

.token.keyword {
  color: rgb(197, 165, 197);
}

.token.string{
  color: rgb(195, 232, 141);
}

.token.attr-name {
  color: rgb(156, 220, 254);
}

.token.variable {
  color: rgb(156, 220, 254);
}

.token.operator {
  color: #EDEDED;
}

.token.entity {
  color: #FFFFB6;
  cursor: help;
}

.token.url {
  color: #96CBFE;
}

.language-css .token.string,
.style .token.string {
  color: #87C38A;
}

.token.atrule,
.token.attr-value {
  color: #F9EE98;
}

.token.function {
  color: rgb(121, 182, 242)
}

.token.regex {
  color: #E9C062;
}

.token.important {
  color: #fd971f;
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}
.header{
    background-color: #7849FF;
    padding: 10px 0px;
    position: sticky;
    position: -webkit-sticky;
    top: 0px;
    z-index: 9999;
    width: 100%;
}
.header .navbar a{
    color: #fff;
}
.header .navbar-dark .navbar-nav .nav-link,
.header .navbar-dark .navbar-nav .nav-link:hover,
.header .navbar-dark .navbar-nav .nav-link:focus,
.header .navbar-dark .navbar-nav .nav-link:active{
    color: #fff;
    margin: 0px 15px;
    border-bottom: 2px solid rgba(0, 0, 0, 0);
}
.header .navbar-dark .navbar-nav .nav-link:hover{
    border-bottom: 2px solid rgba(255, 255, 255, 1);
}

.header .navbar-dark .dropdown-menu a{
    color: #000;
}

.header-button{
    display: inline-block;
    padding: 10px 20px;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    text-decoration: none;
    transition: border-color .3s ease;
    text-align: center;
}
.header-button:hover{
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 1);
    text-decoration: none;
}

@media(max-width:980px){
.header .navbar-dark .navbar-nav .nav-link,
.header .navbar-dark .navbar-nav .nav-link:hover,
.header .navbar-dark .navbar-nav .nav-link:focus,
.header .navbar-dark .navbar-nav .nav-link:active
{
    margin: 0;
    border: 0;
    margin: 15px 0px;
}

.header-button{
    display: block;
    margin: 15px 0px;
} 

.header .navbar-dark .navbar-toggler{
    color: rgb(255 255 255 /50%);
    border-color: rgb(255 255 255 / 50%);
}


}
.footer{
    padding: 100px 0px;
    background-color: #F0F0F0;
}
.footer-logo{
    padding-bottom: 30px;
}
.footer-widget{
    padding: 10px 0px;
}
.footer-widget h4{
    font-size: 18px;
    font-weight: 400;
    letter-spacing: .4px;
    color: #212124;
    padding-bottom: 15px;
}
.footer-widget ul{
    display: block;
    margin: 0;
    padding: 0;
}
.footer-widget ul li{
    display: block;
    padding: 15px 0px;
    font-size: 12px;
}
.footer-widget ul li a{
    font-style: normal;
    color: #212124;
}
.footer-widget ul li a:hover{
    text-decoration: none;
    font-weight: 600;
    color: #212124;
}
.footer-widget p, .footer-widget a{
    font-style: normal;
    color: #212124;
    font-size: 12px;
    margin: 0;
    padding: 0;
}
.footer-widget a:hover{
    text-decoration: none;
    font-weight: 600;
    color: #212124;
}

.footer-social{
    display: block;
    margin: 0;
    padding: 0;
    margin-bottom:30px;
}

.footer-social li{
    display: inline-block;
    padding: 0px 5px;
}
.footer-social li:first-child{
    padding-left: 0;
}
.footer-social li a{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #212124;
    color: #fff;
    display: block;
    text-align: center;
    line-height:40px;
    font-size: 20px;
}
.footer-social li a:hover{
    background-color: #000;
    color: #fff;
    text-decoration: none;
    font-weight: normal;
}
.dropdown-item:active {
    background-color: #777777;
  }

.home-hero{
    background: rgb(147,110,253);
    background: linear-gradient(0deg, rgba(147,110,253,1) 0%, rgba(122,76,255,1) 100%);
    text-align: center;
    color: #fff;
}
.home-hero .hero_text_image{
   width: 315px;
   display: inline-block;
}
.home-hero .content{
    margin-bottom: 30px;
    padding-top: 0;
    padding-bottom: 0;
    font-family: Poppins,sans-serif;
    color: #fff;
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;
    letter-spacing: .1px;
}
.home-hero h1{
    align-self: center;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 70px;
}
.cards{
    background-color: #FAFAFA;
    padding: 70px 0px;
    text-align: center;
    margin-top: -120px;
}
.mobile_image{
    display: none;
}
.white-section{
    background-color: #fff;
    padding: 70px 0px;

}
.title{
    font-weight: 800;
}
.info-box{
    display: block;
    padding: 15px 0px;
    text-align: center;
}
.info-box h3{
    font-weight: 700;
    font-size: 20px;
    padding-bottom: 10px;
}
.new-feature-image{
    max-height: 300px;
}
.text-button{
    color: #7849ff;
    text-decoration: none;
}
.text-button:hover{
    color: #7849ff;
    text-decoration: none;
}
.testibox{
    position: relative;
    left: -35px;
    margin-top: 80px;
    padding: 30px;
    align-items: center;
    border: 2px solid #7849ff;
    border-radius: 10px;
    font-size: 14px;
    color: #717070;
}
.author_data{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.author_data .author_image{

    max-width: 80px;
    border-radius: 50%;
}
.author_data .author_content{
    font-size: 14px;
    color: #717070;
}
.author_data .author_content h4{
    font-size: 16px;
    color: #333;
    font-weight: 600;
}
.testibox .testi_icon{
    margin-top: -50px;
    margin-left: 70px;
}
.testibox .text{
    margin-left: 70px;
    margin-top: 20px;
    margin-bottom: 15px;
}
.overflow_right{
    margin-right: -30px;
    z-index: 5;
    position: relative;
}
.overflow_left{
    margin-left: -30px; 
}
.reviews{
    padding: 40px;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 0 20px 0 rgb(0 0 0 / 10%);
    margin: 15px 0px;
}
.reviews .content_area{
    min-height: 300px;
}

.logos{
    background-color: #FAFAFA;
    padding: 50px 0px;
    text-align:center;
}
.press-logo {
    max-height: 65px;
}



@media(max-width:1024px){
    .overflow_right,.overflow_left{
        margin-right: 0px;
        margin-left: 0px; 
    }
    .testibox{
        left: 0;
        margin-top: 0px;
        border: 0;
    }
    .testibox .text,.testibox .testi_icon{
        margin-left: 0;
    }
}
@media(max-width:980px){
    .home-hero h1{
    font-size: 38px;
    line-height: 48px;
    }
    .white-section{
        text-align: center;
    }
    .desktop_image{
        display: none;
    }
    .mobile_image{
        display: block;
    }
    .cards{
        
        margin-top:-20px;
    }
    .testibox .text, .testibox .testi_icon{
       margin-top: 0;
    }
    .author_data{
        display: block;
        flex: none;
        text-align: center;
    }
    .author_data .author_image{
        display: inline-block;
        max-width: 70px;
        border-radius: 50%;
    }
}
.App {
  text-align: center;
  font-family: 'Poppins', sans-serif;
}
body{
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}
img{
  max-width: 100%;
}
.green-button{
    padding: 15px 60px;
    background-color: #00d28f;
    font-size: 13px;
    font-weight: 600;
    width: 315px;
    border-radius: 8px;
    box-shadow: 0 5px 10px 0 rgb(33 33 36 / 15%);
    transition: box-shadow .3s ease;
    font-family: 'Poppins', sans-serif;
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    text-align: center;
    letter-spacing: .8px;
    text-decoration: none;
    display: inline-block;
    text-transform: uppercase;
}
.green-button:hover{
  box-shadow: 0 5px 10px 0 rgb(33 33 36 / 30%);
  color: #fff;
  text-decoration: none;
}

.purple-button{
  padding: 15px 60px;
  background-color: #7849FF;
  font-size: 13px;
  font-weight: 600;
  width: 315px;
  border-radius: 8px;
  box-shadow: 0 5px 10px 0 rgb(33 33 36 / 15%);
  transition: box-shadow .3s ease;
  font-family: 'Poppins', sans-serif;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  letter-spacing: .8px;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
}
.purple-button:hover{
box-shadow: 0 5px 10px 0 rgb(33 33 36 / 30%);
color: #fff;
text-decoration: none;
}

.purple-button-cta {
  display: block;
  width: 100%;
  margin-top: 0;
  padding-top: 11px;
  padding-bottom: 11px;
  border-radius: 8px;
  background-color: #7849ff;
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  text-align: center;
  letter-spacing: .4px;
}
.purple-button-cta:hover {
  background-color: #663cdf;
  color: #fff;
 text-decoration: none;
}
@media(max-width:980px){
  .purple-button-cta{
    font-size: 10px;
    line-height: 14px;
  }
}
