.header{
    background-color: #7849FF;
    padding: 10px 0px;
    position: sticky;
    position: -webkit-sticky;
    top: 0px;
    z-index: 9999;
    width: 100%;
}
.header .navbar a{
    color: #fff;
}
.header .navbar-dark .navbar-nav .nav-link,
.header .navbar-dark .navbar-nav .nav-link:hover,
.header .navbar-dark .navbar-nav .nav-link:focus,
.header .navbar-dark .navbar-nav .nav-link:active{
    color: #fff;
    margin: 0px 15px;
    border-bottom: 2px solid rgba(0, 0, 0, 0);
}
.header .navbar-dark .navbar-nav .nav-link:hover{
    border-bottom: 2px solid rgba(255, 255, 255, 1);
}

.header .navbar-dark .dropdown-menu a{
    color: #000;
}

.header-button{
    display: inline-block;
    padding: 10px 20px;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    text-decoration: none;
    -webkit-transition: border-color .3s ease;
    transition: border-color .3s ease;
    text-align: center;
}
.header-button:hover{
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 1);
    text-decoration: none;
}

@media(max-width:980px){
.header .navbar-dark .navbar-nav .nav-link,
.header .navbar-dark .navbar-nav .nav-link:hover,
.header .navbar-dark .navbar-nav .nav-link:focus,
.header .navbar-dark .navbar-nav .nav-link:active
{
    margin: 0;
    border: 0;
    margin: 15px 0px;
}

.header-button{
    display: block;
    margin: 15px 0px;
} 

.header .navbar-dark .navbar-toggler{
    color: rgb(255 255 255 /50%);
    border-color: rgb(255 255 255 / 50%);
}


}