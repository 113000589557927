.dropdown-item:active {
    background-color: #777777;
  }

.home-hero{
    background: rgb(147,110,253);
    background: linear-gradient(0deg, rgba(147,110,253,1) 0%, rgba(122,76,255,1) 100%);
    text-align: center;
    color: #fff;
}
.home-hero .hero_text_image{
   width: 315px;
   display: inline-block;
}
.home-hero .content{
    margin-bottom: 30px;
    padding-top: 0;
    padding-bottom: 0;
    font-family: Poppins,sans-serif;
    color: #fff;
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;
    letter-spacing: .1px;
}
.home-hero h1{
    align-self: center;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 70px;
}
.cards{
    background-color: #FAFAFA;
    padding: 70px 0px;
    text-align: center;
    margin-top: -120px;
}
.mobile_image{
    display: none;
}
.white-section{
    background-color: #fff;
    padding: 70px 0px;

}
.title{
    font-weight: 800;
}
.info-box{
    display: block;
    padding: 15px 0px;
    text-align: center;
}
.info-box h3{
    font-weight: 700;
    font-size: 20px;
    padding-bottom: 10px;
}
.new-feature-image{
    max-height: 300px;
}
.text-button{
    color: #7849ff;
    text-decoration: none;
}
.text-button:hover{
    color: #7849ff;
    text-decoration: none;
}
.testibox{
    position: relative;
    left: -35px;
    margin-top: 80px;
    padding: 30px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border: 2px solid #7849ff;
    border-radius: 10px;
    font-size: 14px;
    color: #717070;
}
.author_data{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.author_data .author_image{

    max-width: 80px;
    border-radius: 50%;
}
.author_data .author_content{
    font-size: 14px;
    color: #717070;
}
.author_data .author_content h4{
    font-size: 16px;
    color: #333;
    font-weight: 600;
}
.testibox .testi_icon{
    margin-top: -50px;
    margin-left: 70px;
}
.testibox .text{
    margin-left: 70px;
    margin-top: 20px;
    margin-bottom: 15px;
}
.overflow_right{
    margin-right: -30px;
    z-index: 5;
    position: relative;
}
.overflow_left{
    margin-left: -30px; 
}
.reviews{
    padding: 40px;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 0 20px 0 rgb(0 0 0 / 10%);
    margin: 15px 0px;
}
.reviews .content_area{
    min-height: 300px;
}

.logos{
    background-color: #FAFAFA;
    padding: 50px 0px;
    text-align:center;
}
.press-logo {
    max-height: 65px;
}



@media(max-width:1024px){
    .overflow_right,.overflow_left{
        margin-right: 0px;
        margin-left: 0px; 
    }
    .testibox{
        left: 0;
        margin-top: 0px;
        border: 0;
    }
    .testibox .text,.testibox .testi_icon{
        margin-left: 0;
    }
}
@media(max-width:980px){
    .home-hero h1{
    font-size: 38px;
    line-height: 48px;
    }
    .white-section{
        text-align: center;
    }
    .desktop_image{
        display: none;
    }
    .mobile_image{
        display: block;
    }
    .cards{
        
        margin-top:-20px;
    }
    .testibox .text, .testibox .testi_icon{
       margin-top: 0;
    }
    .author_data{
        display: block;
        flex: none;
        text-align: center;
    }
    .author_data .author_image{
        display: inline-block;
        max-width: 70px;
        border-radius: 50%;
    }
}